import React from 'react';
import { useParams } from 'react-router-dom';
import posts from '../data/posts';
import arrowRight from '../images/arrow-right.png';

function PostDetail() {
    const { postId } = useParams();
    const post = posts.find(p => p.id === parseInt(postId)); 
    if (!post) {
        return <div>Post not found!</div>;
    }
    
    switch (post.layout) {
        case 'layout1':
            return renderLayout1(post);
        case 'layout2':
            return renderLayout2(post);
        case 'layout3':
            return renderLayout3(post);
        case 'layout4':
            return renderLayout4(post);
        case 'layout5':
            return renderLayout5(post);
        case 'layout6':
            return renderLayout6(post);
        case 'layout7':
            return renderLayout7(post);
        default:
            return <div>Unknown layout!</div>;
    }
    
}


function renderLayout1(post) {

  return (
             <div className="container py-4 my-5">
                <div className="row justify-content-between">
                    <div className="col-lg-10">
                        <img className="img-fluid" src={post.background} alt=""/>
                        <h1 className="text-white add-letter-space mt-4">{post.title}</h1>
                        <ul className="post-meta mt-3 mb-4">
                            <li className="d-inline-block mr-3">
                                <span className="fas fa-clock text-primary"></span>
                                <a className="ml-1" >{post.time}</a>
                            </li>
                            <li className="d-inline-block">
                                <span className="fas fa-list-alt text-primary"></span>
                                <a className="ml-1">{post.cat}</a>
                            </li>
                        </ul>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Overview</h3>
                            <h6 className="h6 mb-3">{post.detail}</h6>
                        </div>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Background and Motivation</h3>
                            <h6 className="h6 mb-3">{post.backgroundMotivation.whyTreeShadows}</h6>
                            <h6 className="h6 mb-3">{post.backgroundMotivation.objectiveVision}</h6>
                        </div>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">My Role and Responsibilities</h3>
                            <ul className="h6 mb-3">
                        {post.rolesResponsibilities.map(role => <li key={role}>{role}</li>)}
                         </ul>

                        </div>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Challenges and Solutions</h3>
                            <ul className="h6 mb-3">
                        {post.challenges.map(role => <li key={role}>{role}</li>)}
                         </ul>
                         <img className="img-fluid" src={post.image2} alt=""/>
                        </div>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Showcase</h3>
                        </div>

                        <div className="widget">
                            <img className="img-fluid" src={post.image5} alt=""/>
                            <br></br>
                            <h6 className="h6 mb-3   text-center">{post.img5_dis}</h6>
                        </div>

                        <div className="widget">
                            <img className="img-fluid" src={post.image6} alt=""/>
                            <br></br>
                            <h6 className="h6 mb-3   text-center">{post.img6_dis}</h6>
                        </div>

                        <div className="widget">
                            <img className="img-fluid" src={post.image1} alt=""/>
                            <br></br>
                            <h6 className="h6 mb-3   text-center">{post.img1_dis}</h6>
                        </div>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">{post.img2_dis}</h3>
                            
                        </div>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Final Present</h3>
                            <br></br>
                            <img className="img-fluid" src={post.image3} alt=""/>
                            
                        </div>

                        <div className="blockquote bg-dark my-5">
                            <p className="blockquote-text pl-2">{post.acknowledgments}</p>
                            <span className="blockquote-footer text-white h4 mt-3">Ken Zhan</span>
                        </div>




{/* 
                        <div className="widget">
                            <h1 className="widget-title text-white d-inline-block mb-4">Text styles</h1>
                            <h1 className="h1 mb-3">H1 Heading (Barlow Condensed)</h1>
                            <h2 className="h2 mb-3">H2 Heading (Barlow Condensed)</h2>
                            <h3 className="h3 mb-3">H3 Heading (Barlow Condensed)</h3>
                            <h4 className="h4 mb-3">H4 Heading (Montserrat)</h4>
                            <h5 className="h5 mb-3">H5 Heading (Montserrat)</h5>
                            <h6 className="h6 mb-3">H6 Heading (Montserrat)</h6>
                            
                        </div>

                        <div className="widget">
                            <h1 className="widget-title text-white d-inline-block mb-4">Paragraph</h1>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Metus cum cursus nunc nec velit volutpat consequat. Vitae dui, massa viverra nam dui laoreet ipsum. Sagittis sed feugiat blandit adipiscing mauris. Facilisis dictum in tellus ac turpis. Pretium, facilisis turpis duis pulvinar blandit est. Dolor accumsan pellentesque ullamcorper volutpat urna arcu. Nisi nulla et mauris et, ultricies odio semper gravida. Justo, lorem leo ullamcorper leo ornare phasellus. Dolor tristique sem quam eget tempor aliquet sem amet, eget. Vitae id mattis consectetur </p>
                            
                        </div>

                        <div className="widget">
                            <h1 className="widget-title text-white d-inline-block mb-4">Typefaces</h1>
                            <h3 className="font-primary mb-3">Barlow Condensed</h3>
                            <h3 className="font-secondary">Montserrat</h3>
                            
                        </div>

                        <div className="widget">
                            <h1 className="widget-title text-white d-inline-block mb-4">Table Style</h1>
                            <table className="table table-bordered text-center text-white table-transparent">
                                <thead className="bg-dark">
                                    <tr>
                                        <th className="h3" scope="col">Table</th>
                                        <th className="h3" scope="col">Column</th>
                                        <th className="h3" scope="col">Column</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Row 1.1</td>
                                        <td>Row 2.1</td>
                                        <td>Row 3.1</td>
                                    </tr>
                                    <tr>
                                        <td>Row 1.2</td>
                                        <td>Row 2.2</td>
                                        <td>Row 3.2</td>
                                    </tr>
                                    <tr>
                                        <td>Row 1.3</td>
                                        <td>Row 2.3</td>
                                        <td>Row 3.3</td>
                                    </tr>
                                </tbody>
                            </table>
                         
                        </div>

                        <div className="widget">
                            <h1 className="widget-title text-white d-inline-block mb-4">Button</h1>
                            <div className="d-block">
                                <a className="btn btn-primary" href="#!">Button <img src={arrowRight} alt=""/></a>
                            </div>
                            
                        </div>

                        <div className="widget">
                            <h1 className="widget-title text-white d-inline-block">Colors</h1>
                            <div className="row text-center">
                                <div className="col-sm-3 mt-4">
                                    <div className="rounded">
                                        <div className="bg-black py-5"></div>
                                        <p className="bg-dark py-2">000000</p>
                                    </div>
                                </div>
                                <div className="col-sm-3 mt-4">
                                    <div className="rounded">
                                        <div className="bg-primary py-5"></div>
                                        <p className="bg-dark py-2">EDEDED</p>
                                    </div>
                                </div>
                                <div className="col-sm-3 mt-4">
                                    <div className="rounded">
                                        <div className="bg-dark py-5"></div>
                                        <p className="bg-dark py-2">1D1C21</p>
                                    </div>
                                </div>
                                <div className="col-sm-3 mt-4">
                                    <div className="rounded">
                                        <div className="bg-dark-gray py-5"></div>
                                        <p className="bg-dark py-2">CBCBCB</p>
                                    </div>
                                </div>
                                <div className="col-sm-3 mt-4">
                                    <div className="rounded">
                                        <div className="bg-white py-5"></div>
                                        <p className="bg-dark py-2">FFFFFF</p>
                                    </div>
                                </div>
                                <div className="col-sm-3 mt-4">
                                    <div className="rounded">
                                        <div className="bg-gray py-5"></div>
                                        <p className="bg-dark py-2">B0B0B0</p>
                                    </div>
                                </div>
                                <div className="col-sm-3 mt-4">
                                    <div className="rounded">
                                        <div className="bg-black-700-darken py-5"></div>
                                        <p className="bg-dark py-2">737373</p>
                                    </div>
                                </div>
                            </div>

                        </div> */}
                        
                    </div>
                </div>





                




            </div>

            
  );
}

function renderLayout2(post) {
return (
    <div className="container py-4 my-5">
                <div className="row justify-content-between">
                    <div className="col-lg-10">
                        <img className="img-fluid" src={post.background} alt=""/>
                        <h1 className="text-white add-letter-space mt-4">{post.title}</h1>
                        <ul className="post-meta mt-3 mb-4">
                            <li className="d-inline-block mr-3">
                                <span className="fas fa-clock text-primary"></span>
                                <a className="ml-1" >{post.time}</a>
                            </li>
                            <li className="d-inline-block">
                                <span className="fas fa-list-alt text-primary"></span>
                                <a className="ml-1">{post.cat}</a>
                            </li>
                        </ul>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Overview</h3>
                            <h6 className="h6 mb-3">{post.detail}</h6>
                        </div>
                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">My Role & Responsibilities</h3>
                            <h6 className="h6 mb-3">{post.role}</h6>
                        </div>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Problems Statement</h3>
                            <ul className="h6 mb-3">
                            {post.problem.map(role => <li key={role}>{role}</li>)}
                            </ul>
                            
                        </div>
                        

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Methodology</h3>
                            
                            <h6 className="h6 mb-3">{post.methodology.userTesting.procedure_1}</h6>
                            <h6 className="h6 mb-3">{post.methodology.userTesting.procedure_2}</h6>
                            <h6 className="h6 mb-3">{post.methodology.userTesting.procedure_3}</h6>
                            <h6 className="h6 mb-3">{post.methodology.userTesting.procedure_4}</h6>
                            
                            <div className="row">
                            <div className="col-lg-6 d-inline-block">
                            <img className="img-fluid mb-4" src={post.image1} alt=""/>
                            </div>
                            <div className="col-lg-6">
                            <img className="img-fluid mb-4" src={post.image2} alt=""/>
                            </div>
                            </div>
                            
                            <img className="img-fluid" src={post.image3} alt=""/>

                        </div>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Analysis Techniques</h3>
                            <img className="img-fluid  mb-4" src={post.image4} alt=""/>
                            <h5 className="text-white mb-4">Summary of data analysis</h5>
                            <ul className="h6 mb-3">
                            <li className="h6 mb-3">{post.analysisTechniques.toolsAndMethods.sus}</li>
                            <li className="h6 mb-3">{post.analysisTechniques.toolsAndMethods.matrix}</li>
                            <li className="h6 mb-3">{post.analysisTechniques.toolsAndMethods.postSurveyMethods}</li>
                            </ul>
                        </div>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Key Findings</h3>
                            
                            <ul className="h6 mb-3">
                            <li className="h6 mb-3">{post.keyFindings.positiveInsights}</li>
                            <li className="h6 mb-3">{post.keyFindings.areasOfImprovement}</li>
                             </ul>
                             <img className="img-fluid" src={post.image5} alt=""/>
                        </div>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Design Recommendations</h3>

                            
                            <h6 className="h6 mb-4 text-white">{post.designRecommendations.enhancingVisualFeedback}</h6>
                            <ul className="h6 mb-4">
                               {post.designRecommendations.detail_1.map(role => <li key={role}>{role}</li>)}
                            </ul>
                            <br></br>
                            <h6 className="h6 mb-4 text-white">{post.designRecommendations.improvingUserGuidance}</h6>
                            <ul className="h6 mb-4">
                               {post.designRecommendations.detail_2.map(role => <li key={role}>{role}</li>)}
                            </ul>
                            <br></br>
                            <h6 className="h6 mb-4 text-white">{post.designRecommendations.refiningInterfaceUsability}</h6>
                            <ul className="h6 mb-4">
                               {post.designRecommendations.detail_3.map(role => <li key={role}>{role}</li>)}
                            </ul>
                            <br></br>
                            <h6 className="h6 mb-4 text-white">{post.designRecommendations.contentRecommendations}</h6>
                            <ul className="h6 mb-4">
                               {post.designRecommendations.detail_4.map(role => <li key={role}>{role}</li>)}
                            </ul>
                        </div>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Conclusion</h3>
                            <br></br>
                            <h5 className="text-white d-inline-block mb-4">{post.conclusion.title_1}</h5>
                            <h6 className="h6 mb-3">{post.conclusion.reiteration}</h6>
                            <br></br>
                            <h5 className="text-white d-inline-block mb-4">{post.conclusion.title_2}</h5>
                            <h6 className="h6 mb-3">{post.conclusion.finalThoughts}</h6>
                        
                            
                        </div>
                        
                    </div>
                </div>





                




            </div>
);
}

function renderLayout3(post) {
    return (
        <div className="container py-4 my-5">
                    <div className="row justify-content-between">
                        <div className="col-lg-10">
                            <img className="img-fluid" src={post.background} alt=""/>
                            <h1 className="text-white add-letter-space mt-4">{post.title}</h1>
                            <ul className="post-meta mt-3 mb-4">
                                <li className="d-inline-block mr-3">
                                    <span className="fas fa-clock text-primary"></span>
                                    <a className="ml-1" >{post.time}</a>
                                </li>
                                <li className="d-inline-block">
                                    <span className="fas fa-list-alt text-primary"></span>
                                    <a className="ml-1">{post.cat}</a>
                                </li>
                            </ul>
    
                            <div className="widget">
                                <h3 className="widget-title text-white d-inline-block mb-4">Overview</h3>
                                <h6 className="h6 mb-3">{post.detail}</h6>
                            </div>
                            <div className="widget">
                                <h3 className="widget-title text-white d-inline-block mb-4">My Role & Responsibilities</h3>
                                <h6 className="h6 mb-3">{post.role}</h6>
                            </div>
    
                            <div className="widget">
                                <h3 className="widget-title text-white d-inline-block mb-4">Observation</h3>
                                <img className="img-fluid mb-4" src={post.image6} alt=""/>
                                
                            </div>
                            
    
                            <div className="widget">
                                <h3 className="widget-title text-white d-inline-block mb-4">Information Synthesis & 
Identity Opportunities
</h3>
<img className="img-fluid mb-4" src={post.image7} alt=""/>
<img className="img-fluid mb-4" src={post.image8} alt=""/>
<ul className="h6 mb-3">
                            {post.problem.map(role => <li key={role}>{role}</li>)}
                            </ul>

    
                            </div>
    
                            <div className="widget">
                                <h3 className="widget-title text-white d-inline-block mb-4">Idea Generation & Valuate</h3>
                                <p className=" mb-4">Focusing on how to make parking lots safer, we brainstormed and selected the right ideas to incorporate.</p>
                                <img className="img-fluid  mb-4" src={post.image5} alt=""/>
                                <p className=" mb-4">Then after three rounds of on-site interviews and obtaining feedback, we completed the final design.</p>
                                <img className="img-fluid  mb-4" src={post.image1} alt=""/>
                            </div>
    
                            <div className="widget">
                                <h3 className="widget-title text-white d-inline-block mb-4">Final Design</h3>
                            
                            
                                 <div className="row">
                             <div className="col-lg-6 d-inline-block">
                             <img className="img-fluid mb-4" src={post.background} alt=""/>
                            </div>
                            <div className="col-lg-6">
                            <img className="img-fluid mb-4" src={post.image4} alt=""/>
                            </div>
                            </div>
                            
                            
                            
                            </div>

    

    
                           
                            
                        </div>
                    </div>
    
    
    
    
    
                    
    
    
    
    
                </div>
    );
    }

function renderLayout4(post) {
    return (
<div className="container py-4 my-5">
                <div className="row justify-content-between">
                    <div className="col-lg-10">
                        <img className="img-fluid" src={post.background} alt=""/>
                        <h1 className="text-white add-letter-space mt-4">{post.title}</h1>
                        <ul className="post-meta mt-3 mb-4">
                            <li className="d-inline-block mr-3">
                                <span className="fas fa-clock text-primary"></span>
                                <a className="ml-1" >{post.time}</a>
                            </li>
                            <li className="d-inline-block">
                                <span className="fas fa-list-alt text-primary"></span>
                                <a className="ml-1">{post.cat}</a>
                            </li>
                        </ul>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Overview</h3>
                            <h6 className="h6 mb-3">{post.detail}</h6>
                            <img className="img-fluid mb-4" src={post.image3} alt=""/>
                        </div>

                       

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Design System</h3>
                            <br></br>
                            <img className="img-fluid mb-4" src={post.image1} alt=""/>
                            <img className="img-fluid mb-4" src={post.image2} alt=""/>
                            
                        </div>
                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Design Process</h3>
                            <img className="img-fluid mb-4" src={post.image5} alt=""/>
                        </div>
                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Final Design</h3>
                            <img className="img-fluid mb-4" src={post.image6} alt=""/>
                            <img className="img-fluid mb-4" src={post.image4} alt=""/>
                        </div>
                    </div>
                    </div>
                    </div>
    );
}



function renderLayout5(post) {
    return (
<div className="container py-4 my-5">
                <div className="row justify-content-between">
                    <div className="col-lg-10">
                        <img className="img-fluid" src={post.background} alt=""/>
                        <h1 className="text-white add-letter-space mt-4">{post.title}</h1>
                        <ul className="post-meta mt-3 mb-4">
                            <li className="d-inline-block mr-3">
                                <span className="fas fa-clock text-primary"></span>
                                <a className="ml-1" >{post.time}</a>
                            </li>
                            <li className="d-inline-block">
                                <span className="fas fa-list-alt text-primary"></span>
                                <a className="ml-1">{post.cat}</a>
                            </li>
                        </ul>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Overview</h3>
                            <h6 className="h6 mb-3">{post.detail}</h6>
                        </div>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">{post.dis_1}</h3>
                            
                            <div className="row">
                            <div className="col-lg-6 d-inline-block">
                            <img className="img-fluid mb-4" src={post.image1} alt=""/>
                            </div>
                            <div className="col-lg-6">
                            <img className="img-fluid mb-4" src={post.image2} alt=""/>
                            </div>
                            </div>
                        </div>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">{post.dis_2}</h3>
                            <br></br>
                            <div className="row">
                             <div className="col-lg-6 d-inline-block">
                             <img className="img-fluid mb-4" src={post.image3} alt=""/>
                            </div>
                            <div className="col-lg-6">
                            <img className="img-fluid mb-4" src={post.image4} alt=""/>
                            </div>
                            </div>

                            <div className="row">
                             <div className="col-lg-6 d-inline-block">
                             <img className="img-fluid mb-4" src={post.image5} alt=""/>
                            </div>
                            <div className="col-lg-6">
                            <img className="img-fluid mb-4" src={post.image6} alt=""/>
                            </div>
                            </div>
                        </div>
                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">{post.dis_3}</h3>
                            <div className="row">
                             <div className="col-lg-6 d-inline-block">
                             <img className="img-fluid mb-4" src={post.image7} alt=""/>
                            </div>
                            <div className="col-lg-6">
                            <img className="img-fluid mb-4" src={post.image8} alt=""/>
                            </div>
                            </div>
                        </div>
                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">{post.dis_4}</h3>
                            <div className="row">
                             <div className="col-lg-6 d-inline-block">
                             <img className="img-fluid mb-4" src={post.image9} alt=""/>
                            </div>
                            <div className="col-lg-6">
                            <img className="img-fluid mb-4" src={post.image10} alt=""/>
                            </div>
                            </div>
                            <div className="row">
                             <div className="col-lg-6 d-inline-block">
                             <img className="img-fluid mb-4" src={post.image11} alt=""/>
                            </div>
                            <div className="col-lg-6">
                            
                            </div>
                            </div>
                        </div>
                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">{post.dis_5}</h3>
                            <div className="row">
                             <div className="col-lg-6 d-inline-block">
                             <img className="img-fluid mb-4" src={post.image12} alt=""/>
                            </div>
                            <div className="col-lg-6">
                            <img className="img-fluid mb-4" src={post.image13} alt=""/>
                            </div>
                            </div>
                            <div className="row">
                             <div className="col-lg-6 d-inline-block">
                             <img className="img-fluid mb-4" src={post.image14} alt=""/>
                            </div>
                            <div className="col-lg-6">
                            
                            </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
    );
}


function renderLayout6(post) {
    return (
<div className="container py-4 my-5">
                <div className="row justify-content-between">
                    <div className="col-lg-10">
                        <img className="img-fluid" src={post.background} alt=""/>
                        <h1 className="text-white add-letter-space mt-4">{post.title}</h1>
                        <ul className="post-meta mt-3 mb-4">
                            <li className="d-inline-block mr-3">
                                <span className="fas fa-clock text-primary"></span>
                                <a className="ml-1" >{post.time}</a>
                            </li>
                            <li className="d-inline-block">
                                <span className="fas fa-list-alt text-primary"></span>
                                <a className="ml-1">{post.cat}</a>
                            </li>
                        </ul>

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Overview</h3>
                            <h6 className="h6 mb-3">{post.detail}</h6>
                
                        </div>

                       

                        <div className="widget">
                            <h3 className="widget-title text-white d-inline-block mb-4">Design</h3>
                            <br></br>
                            <img className="img-fluid mb-4" src={post.image1} alt=""/>
                            <img className="img-fluid mb-4" src={post.image2} alt=""/>
                            <img className="img-fluid mb-4" src={post.image3} alt=""/>
                            <img className="img-fluid mb-4" src={post.image4} alt=""/>
                            <img className="img-fluid mb-4" src={post.image5} alt=""/>
                            <img className="img-fluid mb-4" src={post.image6} alt=""/>
                            <img className="img-fluid mb-4" src={post.image7} alt=""/>
                        </div>

                    </div>
                    </div>
                    </div>
    );
}

function renderLayout7(post) {
    return (
      <div className="container py-4 my-5">
        <div className="row justify-content-between">
          <div className="col-lg-10">
            {/* Header */}
            <img className="img-fluid" src={post.background} alt="" />
            <h1 className="text-white add-letter-space mt-4">{post.title}</h1>
            <ul className="post-meta mt-3 mb-4">
              <li className="d-inline-block mr-3">
                <span className="fas fa-clock text-primary"></span>
                <a className="ml-1">{post.time}</a>
              </li>
              <li className="d-inline-block">
                <span className="fas fa-list-alt text-primary"></span>
                <a className="ml-1">{post.cat}</a>
              </li>
            </ul>
  
            {/* Introduction */}
            <div className="widget">
              <h3 className="widget-title text-white d-inline-block mb-4">Overview</h3>
              <h6 className="h6 mb-3">{post.introduction}</h6>
            </div>
  
            {/* Goals */}
            <div className="widget">
              <h3 className="widget-title text-white d-inline-block mb-4">Project Goals</h3>
              <ul className="h6 mb-3">
                {post.goals.map((goal, index) => (
                  <li key={index}>{goal}</li>
                ))}
              </ul>
            </div>
  
            {/* Tools */}
            <div className="widget">
              <h3 className="widget-title text-white d-inline-block mb-4">Tools & Technology</h3>
              <ul className="h6 mb-3">
                <li>
                  <strong>Design:</strong> {post.tools.design.join(", ")}
                </li>
                <li>
                  <strong>Development:</strong> {post.tools.development.join(", ")}
                </li>
                <li>
                  <strong>Collaboration:</strong> {post.tools.collaboration.join(", ")}
                </li>
                <li>
                  <strong>Content Creation:</strong> {post.tools.contentCreation.join(", ")}
                </li>
              </ul>
            </div>
  
            {/* Contributions */}
            <div className="widget">
              <h3 className="widget-title text-white d-inline-block mb-4">Role & Contributions</h3>
              <ul className="h6 mb-3">
                {post.contributions.map((contribution, index) => (
                  <li key={index}>{contribution}</li>
                ))}
              </ul>
            </div>
  
            {/* Challenges */}
            <div className="widget">
              <h3 className="widget-title text-white d-inline-block mb-4">Key Challenges</h3>
              <ul className="h6 mb-3">
                {post.challenges.map((challenge, index) => (
                  <li key={index}>{challenge}</li>
                ))}
              </ul>
            </div>
  
            {/* Process */}
            <div className="widget">
              <h3 className="widget-title text-white d-inline-block mb-4">Process</h3>
              {post.process.map((step, index) => (
                <div key={index} className="mb-3">
                  <h6 className="text-primary">{step.phase}</h6>
                  <p>{step.details.join(" ")}</p>
                </div>
              ))}
            </div>
            <div className="widget text-center">
                            <img className="img-fluid" src={post.image4} alt=""/>
                            <br></br>
                            <h6 className="h6 mb-3   text-center">{post.img4_dis}</h6>
                        </div>
            <div className="widget text-center">
                            <img className="img-fluid text-center" src={post.image3} alt=""/>
                            <br></br>
                            <h6 className="h6 mb-3   text-center">{post.img3_dis}</h6>
                        </div>
  
            {/* Unique Features */}
            <div className="widget">
              <h3 className="widget-title text-white d-inline-block mb-4">Unique Features</h3>
              <ul className="h6 mb-3">
                {post.uniqueFeatures.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
            </div>
  
            {/* Results */}
            <div className="widget">
              <h3 className="widget-title text-white d-inline-block mb-4">Results & Impact</h3>
              <ul className="h6 mb-3">
                {Object.entries(post.results).map(([key, value], index) => (
                  <li key={index}>
                    <strong>{key}:</strong> {value}
                  </li>
                ))}
              </ul>
            </div>
  
            {/* Reflections & Future Plans */}
            <div className="widget">
              <h3 className="widget-title text-white d-inline-block mb-4">Reflections & Next Steps</h3>
              <h6 className="h6 mb-3"><strong>Key Learnings:</strong></h6>
              <ul className="h6 mb-3">
                {post.reflections.keyLearnings.map((learning, index) => (
                  <li key={index}>{learning}</li>
                ))}
              </ul>
              <h6 className="h6 mb-3"><strong>Future Plans:</strong></h6>
              <ul className="h6 mb-3">
                {post.reflections.futurePlans.map((plan, index) => (
                  <li key={index}>{plan}</li>
                ))}
              </ul>
            </div>
            <div className="widget">
                            <img className="img-fluid" src={post.image1} alt=""/>
                            <br></br>
                            <h6 className="h6 mb-3   text-center">{post.img1_dis}</h6>
            </div>
  
            {/* Visuals */}
<div className="widget text-center">
  <h3 className="widget-title text-white d-inline-block mb-4">Visuals</h3>
  <div className="text-center">
    <img className="img-fluid mb-3" src={post.qr} alt="Demo QR Code" />
    <p className="text-white">scan the QR code to view the demo.</p>
  </div>
</div>

  
            {/* Call-to-Action */}
          </div>
        </div>
      </div>
    );
  }
  


export default PostDetail;

