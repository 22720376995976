// About.js
import React from 'react';
import personal_lg from '../images/personal2.jpg';
import arrowRight from '../images/arrow-right.png';

function About() {
    return (
        <div className="container py-4 my-5">
            <div className="row">
                <div className="col-md-9">
                    <img className="img-fluid" src={personal_lg} alt="Ken's portrait"/>
                    <h1 className="text-white add-letter-space my-4">Hi, I’m Keng Zhan (Ken), a web developer and UI/UX designer</h1>
                    <p>With hands-on experience in Agile methodologies and a passion for design, I thrive at the intersection of creativity and technology. I hold a Master's in Design from Monash University and a Bachelor's in Information Technology from RMIT. My professional journey has honed my technical expertise and enhanced my ability to deliver impactful solutions.</p>

                    <h2 className="text-white add-letter-space my-5">I transform ideas into reality with innovative design and robust development.</h2>

                    <ul className="list-unstyled">
                        <li className="bullet-list-item mb-4">
                            <h3 className="text-white mb-3 add-letter-space">Education</h3>
                            <div className='row'>
                                <h5 className="text-white col-lg-7 col-md-7 col-sm-7">Monash University, Melbourne</h5> 
                                <h5 className="item-time text-white col-lg-5 col-md-5 col-sm-5">Mar 2022 - Dec 2023</h5>
                                <h6 className="text-white">Master of Design (Interaction Design)</h6>
                                <p>Key Areas: Interaction Design, 3D Modeling</p>
                            </div>
                            <div className='row mt-3'>
                                <h5 className="text-white col-lg-6 col-md-7 col-sm-7">RMIT, Melbourne</h5> 
                                <h5 className="item-time text-white col-lg-6 col-md-5 col-sm-5">June 2015 - Feb 2018</h5>
                                <h6 className="text-white">Bachelor of Information Technology</h6>
                                <p>Key Areas: Web Development, UI Design, Mobile App Development with Swift and Xcode</p>
                            </div>
                        </li>

                        <li className="bullet-list-item mb-4">
                            <h3 className="text-white mb-3 add-letter-space">Key Skills</h3>
                            <div className='row'>
                                <div className="col-lg-6 col-md-6">
                                    <h6 className="text-white">Technical Skills:</h6>
                                    <p>• Web Development: HTML, CSS, JavaScript, React, Node.js, Shopify, Wordpress</p>
                                    <p>• UI/UX Tools: Figma, Axure</p>
                                    <p>• 3D Modeling: Maya, C4D</p>
                                    <p>• Adobe: Photoshop, Illustrator</p>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <h6 className="text-white">Non-Technical Skills:</h6>
                                    <p>• Agile Methodology • Problem-solving • Teamwork • Communication</p>
                                </div>
                            </div>
                        </li>

                        <li className="bullet-list-item mb-4">
                            <h3 className="text-white mb-3 add-letter-space">Employment History</h3>
                            <div className='row'>
                                <h5 className="text-white col-lg-7 col-md-7 col-sm-7">Ringo Space Art, Hangzhou</h5> 
                                <h5 className="item-time text-white col-lg-5 col-md-5 col-sm-5">Apr 2021 - Dec 2021</h5>
                                <h6>Operator, Developer</h6>
                                <p>Developed and designed the official and marketing websites, collaborating closely with artists and designers.</p>
                            </div>
                            <div className='row mt-3'>
                                <h5 className="text-white col-lg-6 col-md-7 col-sm-7">Coring Technology PTY LTD, Melbourne</h5> 
                                <h5 className="item-time text-white col-lg-6 col-md-5 col-sm-5">Oct 2019 - Oct 2020</h5>
                                <h6>Full Stack Developer</h6>
                                <p>Built commercial websites, provided technical support, and optimized both front-end and back-end functionalities.</p>
                            </div>
                            <div className='row mt-3'>
                                <h5 className="text-white col-lg-6 col-md-7 col-sm-7">Metric Education, Melbourne</h5> 
                                <h5 className="item-time text-white col-lg-6 col-md-5 col-sm-5">June 2019 - Oct 2019</h5>
                                <h6>Web Developer</h6>
                                <p>Enhanced corporate websites and online systems using ASP.NET and Azure Cloud technologies.</p>
                            </div>
                        </li>
                    </ul>

                    <div className="widget text-center">
                        <h3 className="widget-title text-white d-inline-block mb-4">Resume Download</h3>
                        <div className="d-block">
                            <a className="btn btn-primary" href="/public/Ken_Zhan_resume.pdf" download>Download <img src={arrowRight} alt="arrow icon"/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
