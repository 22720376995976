// data/posts.js
import p1_bg from '../images/post/post-lg/p1_bg.png';
import p1_01 from '../images/post/post-lg/p1_01.png';
import p1_02 from '../images/post/post-lg/p1_02.png';
import p1_03 from '../images/post/post-lg/p1_03.jpg';
import p1_04 from '../images/post/post-lg/p1_04.jpg';
import p1_05 from '../images/post/post-lg/p1_05.jpg';
import p1_06 from '../images/post/post-lg/p1_06.jpg';

import p2_bg from '../images/post/post-lg/p2_bg.png';
import p2_01 from '../images/post/post-lg/p2_01.png';
import p2_02 from '../images/post/post-lg/p2_02.png';
import p2_03 from '../images/post/post-lg/p2_03.png';
import p2_04 from '../images/post/post-lg/p2_04.png';
import p2_05 from '../images/post/post-lg/p2_05.png';

import p3_bg from '../images/post/post-lg/p3_bg.png';
import p3_01 from '../images/post/post-lg/p3_01.png';
import p3_02 from '../images/post/post-lg/p3_02.png';
import p3_03 from '../images/post/post-lg/p3_03.png';
import p3_04 from '../images/post/post-lg/p3_04.png';
import p3_05 from '../images/post/post-lg/p3_05.png';
import p3_06 from '../images/post/post-lg/p3_06.png';
import p3_07 from '../images/post/post-lg/p3_07.png';
import p3_08 from '../images/post/post-lg/p3_08.png';

import p4_bg from '../images/post/post-lg/p4_bg.png';
import p4_01 from '../images/post/post-lg/p4_01.png';
import p4_02 from '../images/post/post-lg/p4_02.png';
import p4_03 from '../images/post/post-lg/p4_03.png';
import p4_04 from '../images/post/post-lg/p4_04.png';
import p4_05 from '../images/post/post-lg/p4_05.png';
import p4_06 from '../images/post/post-lg/p4_06.png';

import p5_bg from '../images/post/post-lg/p5_bg.jpg';
import p5_01 from '../images/post/post-lg/p5_01.jpg';
import p5_02 from '../images/post/post-lg/p5_02.jpg';
import p5_03 from '../images/post/post-lg/p5_03.jpg';
import p5_04 from '../images/post/post-lg/p5_04.jpg';
import p5_05 from '../images/post/post-lg/p5_05.jpg';
import p5_06 from '../images/post/post-lg/p5_06.jpg';
import p5_07 from '../images/post/post-lg/p5_07.jpg';
import p5_08 from '../images/post/post-lg/p5_08.jpg';
import p5_09 from '../images/post/post-lg/p5_09.jpg';
import p5_10 from '../images/post/post-lg/p5_10.jpg';
import p5_11 from '../images/post/post-lg/p5_11.jpg';
import p5_12 from '../images/post/post-lg/p5_12.jpg';
import p5_13 from '../images/post/post-lg/p5_13.jpg';
import p5_14 from '../images/post/post-lg/p5_14.jpg';



import p6_bg from '../images/post/post-lg/p6_bg.png';
import p6_01 from '../images/post/post-lg/p6_01.png';
import p6_02 from '../images/post/post-lg/p6_02.png';
import p6_03 from '../images/post/post-lg/p6_03.png';
import p6_04 from '../images/post/post-lg/p6_04.png';
import p6_05 from '../images/post/post-lg/p6_05.png';
import p6_06 from '../images/post/post-lg/p6_06.png';
import p6_07 from '../images/post/post-lg/p6_07.png';
// import p6_08 from '../images/post/post-lg/p6_08.jpg';
// import p6_09 from '../images/post/post-lg/p6_09.jpg';
// import p6_10 from '../images/post/post-lg/p6_10.jpg';
// import p6_11 from '../images/post/post-lg/p6_11.jpg';
// import p6_12 from '../images/post/post-lg/p6_12.jpg';
// import p6_13 from '../images/post/post-lg/p6_13.jpg';
// import p6_14 from '../images/post/post-lg/p5_14.jpg';

import p7_bg from '../images/post/post-lg/p7_bg.png';
import p7_01 from '../images/post/post-lg/p7_01.png';
import p7_02 from '../images/post/post-lg/p7_02.png';
import p7_03 from '../images/post/post-lg/p7_03.png';
import p7_04 from '../images/post/post-lg/p7_04.png';
import p7_05 from '../images/post/post-lg/p7_05.png';
const posts = [
  {
    id: 7,
    layout: "layout7",
    title: "Stress-Relief AR Mystery Box Project",
    content: "An immersive AR experience blending physical and digital realms, offering stress relief through engaging and interactive mystery boxes.",
    client: "Academic Project",
    cat: "UX Research, AR",
    time:"Aug-Oct 2023",
    role: ["UX Designer", "Developer"],
    background: p7_bg,
    image1:p7_01,
    image3:p7_03,
    image4:p7_04,
    image5:p7_05,
    qr:p7_02,
    img1_dis:"In-game demonstration screenshot",
    img4_dis:"Research & Insights",
    img3_dis:"Developing at Aero for demo",
    //img4_dis:"In-game demonstration screenshot",

    introduction: "In today's fast-paced society, stress and anxiety are prevalent challenges. While many digital tools offer relaxation, few provide the immersive and interactive escape that users crave. This project bridges the physical and digital realms using Augmented Reality (AR) to deliver a unique stress-relief experience through AR-enhanced mystery boxes.",
    
    goals: [
      "Seamlessly Blend Physical and Digital Worlds: Enable users to access AR mystery boxes via QR codes on physical objects and digital platforms.",
      "Offer Diverse Stress-Relief Content: Curate mini-games, music, videos, and visuals tailored to different preferences.",
      "Maximize Engagement: Leverage AR technology to create an immersive and interactive platform.",
    ],
    tools: {
      design: ["Adobe Aero", "Blender", "Maya", "Figma"],
      development: ["ARKit", "ARCore"],
      collaboration: ["Google Workspace", "Notion"],
      contentCreation: ["Midjourney", "SongR", "AIVA"],
    },
    contributions: [
      "User Research: Conducted surveys and interviews to identify stress-relief behaviors and preferences.",
      "AR Interface Design: Designed interactive unboxing animations and seamless navigation for AR experiences.",
      "Content Development: Integrated diverse relaxation content (games, music, visuals) into the platform.",
      "Prototyping & Testing: Built interactive prototypes, collected feedback, and iterated to enhance usability and engagement.",
    ],
    challenges: [
      "Interactive Unboxing: Creating an AR unboxing experience as engaging as physical blind boxes.",
      "Content Personalization: Catering to a broad range of relaxation preferences without sacrificing quality.",
      "Seamless Integration: Bridging the physical and digital spaces effectively with QR code interactions.",
    ],
    process: [
      {
        phase: "Research & Insights",
        details: [
          "Conducted a survey with 50+ participants to identify relaxation habits:",
          "85% preferred quick, engaging activities like music or games.",
          "80% showed enthusiasm for AR-based experiences.",
          "Identified users' desire for surprise elements and easy access via QR codes.",
        ],
      },
      {
        phase: "Ideation",
        details: [
          "Brainstormed AR mystery box themes: relaxation games, calming visuals, humorous videos, and interactive music.",
          "Designed immersive unboxing animations, such as ribbons unwrapping or objects transforming in AR.",
        ],
      },
      {
        phase: "Prototyping & Development",
        details: [
          "Developed prototypes in Blender, Maya, and Adobe Aero, focusing on user-friendly interactions.",
          "Created AR environments with dynamic visual effects, making the unboxing process intuitive and engaging.",
        ],
      },
      {
        phase: "User Testing & Iteration",
        details: [
          "Tested prototypes with target users and iterated based on feedback:",
          "Improved AR navigation to reduce friction.",
          "Enhanced content diversity to minimize repetition.",
          "Added onboarding tutorials for first-time users.",
        ],
      },
    ],
    uniqueFeatures: [
      "Dynamic AR Mystery Boxes: Virtual boxes transform or 'unwrap' in AR, revealing diverse content.",
      "Hybrid Access Points: QR codes seamlessly integrate with both physical objects (e.g., posters) and digital platforms.",
      "Content Personalization: Tailored experiences based on user preferences and interactions.",
    ],
    results: {
      engagement: "90% of testers reported the AR experience as engaging and stress-relieving.",
      accessibility: "QR codes proved intuitive, even for users less familiar with AR technology.",
      preferences: "Mini-games and calming music were the most favored features for immediate relaxation.",
    },
    reflections: {
      keyLearnings: [
        "Combining AR technology with user-centered design offers a powerful solution for modern stress relief.",
        "Iterative testing and feedback are crucial for refining user experiences and maintaining engagement.",
      ],
      futurePlans: [
        "Geolocation Integration: Develop an outdoor AR treasure hunt feature for immersive exploration.",
        "Content Expansion: Collaborate with artists and therapists to diversify relaxation content.",
        "Performance Optimization: Ensure compatibility across a wider range of devices to increase accessibility.",
      ],
    },
    conclusion:
      "The Stress-Relief AR Mystery Box project successfully demonstrates how AR can transform relaxation into an engaging and interactive experience, setting a new standard for digital wellness solutions.",
    visuals: [
      { type: "image", src: "path_to_mockup.jpg", alt: "AR Interface Mockup" },
      { type: "video", src: "path_to_demo.mp4", alt: "Project Demo Video" },
    ],
    callToAction: [
      { label: "View Demo", url: "#" },
      { label: "GitHub Repository", url: "https://github.com/zkkken/personal_web.git" },
    ],
  },



    {
      id: 1,
      layout: "layout1",
      title: "LeafDappled",
      cat:"TEI project, Arduino, Protopie, Group Wrok",
      content: "This project combines nature's therapeutic qualities with technology, creating a lamp that mimics sunlight through leaves. Accompanied by an app, it offers interactive light and shadow experiences for stress relief.",
      time:"Feb-Jun 2023",
      detail:"A unique lamp designed to create the calming influence of tree shadows within indoor spaces, controlled through gesture sensing and mobile applications.",
      background: p1_bg,
      image1:p1_01,
      image2:p1_02,
      image3:p1_03,
      image4:p1_04,
      image5:p1_05,
      image6:p1_06,
      img1_dis:"Light and shadow experiments",
      img2_dis:"Video will be upload soon",
      img3_dis:"",
      img4_dis:"",
      img5_dis:"UI Interface for the App",
      img6_dis:"Map of the whole light control with gesture and app",
      
      backgroundMotivation: {
        whyTreeShadows: "We chose the motif of tree shadows, a universally recognized and tranquil visual, to foster serenity and uplift moods.",
        objectiveVision: "Our goal was to incorporate natural calmness into indoor spaces by mimicking dappled tree shadows."
      },
      rolesResponsibilities: [
        "Concept Analysis: Analyzing the theme of light and shadow.",
        "Preliminary Research:   Selection of materials and evaluation of hardware/software.",
        "Prototype Refinement:   Improving the prototype's design for an authentic shadow effect.",
       "UI and Functional Design:   Creating the user interface and defining functions.",
       "Circuitry Layout:   Planning and implementing the circuitry.",
"Control Code Writing:   Crafting the control code for lights, colors, and switches.",
"Testing:   Rigorous hardware and software testing.",
"Rapid Prototyping:   Using Protopie for prototype development.",
"Final Presentation:   Successful display of the final product.",
      ],
      challenges: [
        "Main Challenge: Achieving the perfect interplay of light and shadow to induce calming effects.",
"Multifaceted Responsibility: Integrated circuit design, hardware connections, and light/shadow design.",
"My Approach: Leveraging my background in IT, I swiftly navigated complex integrations, not just crafting control code but holistically integrating various aspects.",]
        ,
      showcase: {

        demonstration: "A hands-on experience of controlling lights, colors, and switches through gesture sensing and mobile applications.",
        userFeedback: "If available, include user reviews or testimonials here"
      },
      conclusion: "A successfully implemented lamp that recreates the soothing ambiance of tree shadows.",
      acknowledgments: "Special thanks to My team members Kexin Zhou and Yunxi Zhang",
    },
    {
      "id": 2,
      "layout": "layout2",
      "title": "User Research: Slowly App, Group Wrok",
      "cat": "UX Research",
      "content": "An in-depth usability study conducted on the Slowly social app to identify and propose enhancements to its unique slow socializing user experience.",
      "time": "Feb-Jun 2023",
      "detail": "In a usability study on the Slowly app, we tested six participants using the 'think aloud' method. Several usability issues like unclear buttons, difficult navigation, and poor notification systems were identified. Data collection included both qualitative feedback from users and quantitative measures like time spent and error rates. From this, we proposed five design recommendations to improve UI clarity, navigation, content discovery, and user experience. Our goal is to optimize Slowly's unique slow socializing concept, making it more intuitive and user-friendly.",
      "role":"Initial app testing, designing various levels of survey questionnaires, interviewing users, integrating and analyzing data, providing primary modification suggestions.",
      "background": p2_bg,
      "image1": p2_01,
       "image2": p2_02,
      "image3": p2_03,
      "image4": p2_04,
      "image5": p2_05,
      //"image6": p2_06,
      introduction: {
        objective: "To understand and enhance the user experience of the Slowly app, focusing on its unique slow socializing approach."
      },
      problem:[
        "User Experience and Satisfaction: examine how to improve user experience and satisfaction on the Slowly platform, including interface design, feature optimisation, and interaction flow. Understanding users' needs, preferences and pain points, and providing better user, interfaces and features to improve user experience and satisfaction.",

"Social Relationships and Deep Communication: Examine Slowly's role in building and maintaining deep interpersonal relationships. Explore the effectiveness and mechanics of the Slowly platform in facilitating meaningful, deep communication and how it meets users' needs for emotional connection and deep communication.",

"Social impact and user behaviour: examine the impact of Slowly on user behaviour and social impact. Understanding user communication behaviours, social interactions and patterns of interaction between users on the Slowly platform, and the potential impact of the Slowly platform on user social behaviour and mental health.",

      ],
      methodology: {
        userTesting: {
          
          procedure_1:"For our study on Slowly, we utilized a combination of different methods to ensure a comprehensive and holistic understanding of user experiences.",

          procedure_2:"Moderated in-person user testing was the primary method of data collection, where participants interacted with the application under the guidance of a moderator. The moderator explained the process, answered any questions, and ensured that the participants felt comfortable throughout the process.",
          
          procedure_3:"In Tasks and Scenarios part, participants were given specific tasks to perform within the application, such as finding a certain function, setting up notifications, navigating to a certain page, or managing their interests. These tasks were designed to cover all the main features of the application and evaluate their usability.",
          
          procedure_4:"While performing tasks, We used the “think out loud” method. Participants were encouraged to verbalize their thoughts, feelings, and decisions in real-time. This gave us direct insights into their cognitive process and helped us understand their frustrations, confusion, satisfaction, and other reactions while interacting with the application."
        },
        dataCollection: {
          qualitative: "Qualitative: Observations, verbal feedback, and interactions during the testing.",
          quantitative: "Quantitative: Measurable metrics like time spent, error rates, success rates, etc."
        }
      },
      analysisTechniques: {
        toolsAndMethods: {
          sus: "Participants spent most of their time and effort on tasks 3 and 1, with task 3 taking the most time and having the highest failure rate. On task 4, they had the lowest time expenditure and the highest success rate.",
          matrix: "The System Usability Scale (SUS) scores were generally low, with an average of only 53.75, falling into the 'poor' category.",
          postSurveyMethods: "In the post-survey, the general attitude was neutral. However, when asked whether they would recommend the app to their friends, about the stamp system, and about their satisfaction with the design, most chose options that were not negative."
        }
      },
      keyFindings: {
        positiveInsights: "Highlight what users liked and what worked well in the app.",
        areasOfImprovement: "Discuss primary concerns like unclear buttons, navigation difficulties, and notification system inadequacies."
      },
      designRecommendations: {
        enhancingVisualFeedback: "1.Enhance Visual Feedback and Interface Clarity",
        improvingUserGuidance: "2.User Guidance and Information:",
        refiningInterfaceUsability: "3.Interface Usability and Efficiency:",
        contentRecommendations: "4.Content Recommendations",
        detail_1:[
          "Provide visual feedback or changes after clicking the 'Auto Add Friends' button to ensure users understand the action has been processed.",
          "Make the 'Find Friends by ID' button more noticeable by positioning it prominently and adding a border.",
          "Use visually distinct elements, such as badges or notification indicators, to draw users' attention to friend requests.",
          "Improve the clarity of icons representing user or message statuses. Consider using tooltips or explanatory text for each symbol."
          
        ],
        detail_2:["Guide users to meet the 100-word requirement for the initial message in a letter exchange. Provide prompts or examples of topics they can discuss.",
        "Make it clear for users how to unlock and use the multimedia sharing function. Inform them about the requirement of the recipient's consent to insert pictures in letters.",
         "Give users hints on how to find the option to invite pen pals for permission to insert pictures or recordings when they click on the grayed-out icon."],
        detail_3:[
        "Implement a search bar at the top of the scrolling page to help users quickly find specific interest tags. Arrange the interest tags in alphabetical order from A to Z.",
        "Fix the draft function to correctly include inserted photos and prevent unexpected data loss. Consider adding an autosave feature and provide a user-friendly interface for managing and recovering drafts."],
        detail_4:["Consider looking at other social apps to understand their designs and feature placements for better user experience. Industry benchmarks can provide insights into standard practices for UI/UX designs.",],
      },
      conclusion: {
        reiteration: "Slowly's 'slow social' concept has been well received by its target user base. Many users say that the app offers them a new and peaceful way to socialize and find a moment of peace in their fast-paced lives. In addition, users appreciated Slowly's letter exchange method, which they felt added a sense of fun and anticipation to socializing.",
        finalThoughts: "While Slowly's unique concept has garnered much love from users, our findings point to several areas where the user experience could be significantly improved. These areas include the enhancement of visual feedback and interface clarity, offering better user guidance and information, improving interface usability and efficiency, and making content recommendations. To elevate the overall user experience, we strongly suggest that Slowly focuses on these improvements, with a particular emphasis on refining the user interface and enhancing the user experience. By implementing these recommendations, Slowly can evolve into a more user-friendly and intuitive platform for its users, making navigation effortless and enriching the overall user interaction",
        title_1:"Good News",
        title_2:"Bad News",
      },
    },
    
    {
      "id": 3,
      "layout": "layout3",
      "title": "Redesign of Melbourne Shopping Mall Car Parks",
      "cat": "Public Design, UX Research, Group Wrok",
      "content": "Recognizing safety issues in Melbourne mall car parks, we redesigned the space using design thinking, incorporating pedestrian walkways, light strips, and enhanced visuals. After prototyping and gathering feedback, our final layout bolstered safety and improved the overall user experience.",
      "time": "Apr-Jun 2022",
      "detail": "This project emerged from recognizing the safety hazards present in local Melbourne shopping mall car parks, which lacked effective pedestrian-vehicle segregation. Through firsthand observation, interviews with passersby, and progressive application of design thinking, we undertook a redesign of the entire parking space based on the data and   gathered. The redesign featured new pedestrian walkways, navigation light strips, larger floor icons, and more engaging wall art. We built a prototype and conducted random interviews with pedestrians in the car park, refining our design based on the feedback received to deliver the final layout. Our aim was to not only improve safety but also enhance the overall user experience in these parking spaces.",
      "role":"Completing initial individual testing, conducting interviews, providing improvement suggestions, preliminary prototype design and its rendering.",
      "background": p3_bg,
      "image1": p3_01,
       "image2": p3_02,
      "image3": p3_03,
      "image4": p3_04,
      "image5": p3_05,
      "image6": p3_06,
      "image7": p3_07,
      "image8": p3_08,
      introduction: {
        objective: "To understand and enhance the user experience of the Slowly app, focusing on its unique slow socializing approach."
      },
      problem:[
        "HMW keep us safe in car parks (between people and space) ?",
"HMW improve the parking lot environment (light and sound)?",
"HMW created a more concentrated space for people who take their kids to the parking lot?",
"HMW make it easier for people to find where they want to go？",
"HMW reduce the weight of people carried after shopping?",
"HMW improve the effectiveness of car park information interaction？",
"HMW optimize the recognizability of signage?",
"HMW make more people-oriented parking layout arrangement？",],
      methodology: {
        userTesting: {
          
          procedure_1:"For our study on Slowly, we utilized a combination of different methods to ensure a comprehensive and holistic understanding of user experiences.",

          procedure_2:"Moderated in-person user testing was the primary method of data collection, where participants interacted with the application under the guidance of a moderator. The moderator explained the process, answered any questions, and ensured that the participants felt comfortable throughout the process.",
          
          procedure_3:"In Tasks and Scenarios part, participants were given specific tasks to perform within the application, such as finding a certain function, setting up notifications, navigating to a certain page, or managing their interests. These tasks were designed to cover all the main features of the application and evaluate their usability.",
          
          procedure_4:"While performing tasks, We used the “think out loud” method. Participants were encouraged to verbalize their thoughts, feelings, and decisions in real-time. This gave us direct insights into their cognitive process and helped us understand their frustrations, confusion, satisfaction, and other reactions while interacting with the application."
        },
        dataCollection: {
          qualitative: "Qualitative: Observations, verbal feedback, and interactions during the testing.",
          quantitative: "Quantitative: Measurable metrics like time spent, error rates, success rates, etc."
        }
      },
      analysisTechniques: {
        toolsAndMethods: {
          sus: "Participants spent most of their time and effort on tasks 3 and 1, with task 3 taking the most time and having the highest failure rate. On task 4, they had the lowest time expenditure and the highest success rate.",
          matrix: "The System Usability Scale (SUS) scores were generally low, with an average of only 53.75, falling into the 'poor' category.",
          postSurveyMethods: "In the post-survey, the general attitude was neutral. However, when asked whether they would recommend the app to their friends, about the stamp system, and about their satisfaction with the design, most chose options that were not negative."
        }
      },
      keyFindings: {
        positiveInsights: "Highlight what users liked and what worked well in the app.",
        areasOfImprovement: "Discuss primary concerns like unclear buttons, navigation difficulties, and notification system inadequacies."
      },
      designRecommendations: {
        enhancingVisualFeedback: "1.Enhance Visual Feedback and Interface Clarity",
        improvingUserGuidance: "2.User Guidance and Information:",
        refiningInterfaceUsability: "3.Interface Usability and Efficiency:",
        contentRecommendations: "4.Content Recommendations",
        detail_1:[
          "Provide visual feedback or changes after clicking the 'Auto Add Friends' button to ensure users understand the action has been processed.",
          "Make the 'Find Friends by ID' button more noticeable by positioning it prominently and adding a border.",
          "Use visually distinct elements, such as badges or notification indicators, to draw users' attention to friend requests.",
          "Improve the clarity of icons representing user or message statuses. Consider using tooltips or explanatory text for each symbol."
          
        ],
        detail_2:["Guide users to meet the 100-word requirement for the initial message in a letter exchange. Provide prompts or examples of topics they can discuss.",
        "Make it clear for users how to unlock and use the multimedia sharing function. Inform them about the requirement of the recipient's consent to insert pictures in letters.",
         "Give users hints on how to find the option to invite pen pals for permission to insert pictures or recordings when they click on the grayed-out icon."],
        detail_3:[
        "Implement a search bar at the top of the scrolling page to help users quickly find specific interest tags. Arrange the interest tags in alphabetical order from A to Z.",
        "Fix the draft function to correctly include inserted photos and prevent unexpected data loss. Consider adding an autosave feature and provide a user-friendly interface for managing and recovering drafts."],
        detail_4:["Consider looking at other social apps to understand their designs and feature placements for better user experience. Industry benchmarks can provide insights into standard practices for UI/UX designs.",],
      },
      conclusion: {
        reiteration: "Slowly's 'slow social' concept has been well received by its target user base. Many users say that the app offers them a new and peaceful way to socialize and find a moment of peace in their fast-paced lives. In addition, users appreciated Slowly's letter exchange method, which they felt added a sense of fun and anticipation to socializing.",
        finalThoughts: "While Slowly's unique concept has garnered much love from users, our findings point to several areas where the user experience could be significantly improved. These areas include the enhancement of visual feedback and interface clarity, offering better user guidance and information, improving interface usability and efficiency, and making content recommendations. To elevate the overall user experience, we strongly suggest that Slowly focuses on these improvements, with a particular emphasis on refining the user interface and enhancing the user experience. By implementing these recommendations, Slowly can evolve into a more user-friendly and intuitive platform for its users, making navigation effortless and enriching the overall user interaction",
        title_1:"Good News",
        title_2:"Bad News",
      },
    },
    {
      id: 4,
      layout: "layout4",
      title: "Walk Together - Redesign",
      cat:"UI Design",
      content: "The design seeks to enhance the relationship between individuals by integrating physical activity and social interaction. By participating in walking or running together online, people can accompany and support each other. The whole redesign is based on the walk together app design I designed myself before, and the purpose of further optimizing the design is to boost emotional satisfaction by utilizing a minimalist design and Brutalist design approach to evoke feelings of relaxation in users.",
      time:"Feb-Jun 2023",
      detail:"The design seeks to enhance the relationship between individuals by integrating physical activity and social interaction. By participating in walking or running together online, people can accompany and support each other. The whole redesign is based on the walk together app design I designed myself before, and the purpose of further optimizing the design is to boost emotional satisfaction by utilizing a minimalist design and Brutalist design approach to evoke feelings of relaxation in users.",
      background: p4_bg,
      image1:p4_01,
      image2:p4_02,
      image3:p4_03,
      image4:p4_04,
      image5:p4_05,
      image6:p4_06,
      img1_dis:"Light and shadow experiments",
      img2_dis:"Video will be upload soon",
      img3_dis:"",
      img4_dis:"",
      img5_dis:"UI Interface for the App",
      img6_dis:"Map of the whole light control with gesture and app",
      
      backgroundMotivation: {
        whyTreeShadows: "We chose the motif of tree shadows, a universally recognized and tranquil visual, to foster serenity and uplift moods.",
        objectiveVision: "Our goal was to incorporate natural calmness into indoor spaces by mimicking dappled tree shadows."
      },
      rolesResponsibilities: [
        "Concept Analysis: Analyzing the theme of light and shadow.",
        "Preliminary Research:   Selection of materials and evaluation of hardware/software.",
        "Prototype Refinement:   Improving the prototype's design for an authentic shadow effect.",
       "UI and Functional Design:   Creating the user interface and defining functions.",
       "Circuitry Layout:   Planning and implementing the circuitry.",
"Control Code Writing:   Crafting the control code for lights, colors, and switches.",
"Testing:   Rigorous hardware and software testing.",
"Rapid Prototyping:   Using Protopie for prototype development.",
"Final Presentation:   Successful display of the final product.",
      ],
      challenges: [
        "Main Challenge: Achieving the perfect interplay of light and shadow to induce calming effects.",
"Multifaceted Responsibility: Integrated circuit design, hardware connections, and light/shadow design.",
"My Approach: Leveraging my background in IT, I swiftly navigated complex integrations, not just crafting control code but holistically integrating various aspects.",]
        ,
      showcase: {

        demonstration: "A hands-on experience of controlling lights, colors, and switches through gesture sensing and mobile applications.",
        userFeedback: "If available, include user reviews or testimonials here"
      },
      conclusion: "A successfully implemented lamp that recreates the soothing ambiance of tree shadows.",
      acknowledgments: "Special thanks to My team members Kexin Zhou and Yunxi Zhang",
    },
    {
      id: 5,
      layout: "layout5",
      title: "Rainbow Serpent Festival ",
      cat:"VI Design",
      content: "To create a comprehensive Visual Identity (VI) design for the Rainbow Serpent Festival, incorporating regional culture and emotive elements.",
      role:"",
      time:"April 2022",
      detail:"For the Rainbow Serpent Festival, I created VI Design. The logo was redone based on regional culture and combined with soul rock and more emotive elements. Create traditional marketing materials like posters and tickets, as well as a desktop and mobile version of the website. Making use of Mokeup to design related items like cups and t-shirts.",
      background: p5_bg,
      image1: p5_01,
      image2: p5_02,
      image3: p5_03,
      image4: p5_04,
      image5: p5_05,
      image6: p5_06,
      image7: p5_07,
      image8: p5_08,
      image9: p5_09,
      image10: p5_10,
      image11: p5_11,
      image12: p5_12,
      image13: p5_13,
      image14: p5_14,
      dis_1:"Logo Design",
      dis_2:"Design System",
      dis_3:"Traditional Marketing Materials",
      dis_4:"Digital Presence",
      dis_5:"Merchandise Design with Mokeup",

      // ... 其他属性 ...
    },

    {
      id: 6,
      layout: "layout6",
      title: "Stick Note - Phone Design",
      cat:"Product Design",
      content: "Make out the design of the next-generation mobile device. It is inspired by the sticky notes we use every day and completely inherits their advantages of being convenient and noticeable. The mobile is as thin as paper and, when used in 'STICKY' mode, can stick anywhere just like a real sticky note. There is also a carrying module that can serve as a professional camera module that can be removed as well as an additional Bluetooth sound / speaker.",
      time:"Apr-Jun 2022",
      detail:"Make out the design of the next-generation mobile device. It is inspired by the sticky notes we use every day and completely inherits their advantages of being convenient and noticeable. The mobile is as thin as paper and, when used in 'STICKY' mode, can stick anywhere just like a real sticky note. There is also a carrying module that can serve as a professional camera module that can be removed as well as an additional Bluetooth sound / speaker.",
      background: p6_bg,
      image1:p6_01,
      image2:p6_02,
      image3:p6_03,
      image4:p6_04,
      image5:p6_05,
      image6:p6_06,
      image7:p6_07,

      img1_dis:"Light and shadow experiments",
      img2_dis:"Video will be upload soon",
      img3_dis:"",
      img4_dis:"",
      img5_dis:"UI Interface for the App",
      img6_dis:"Map of the whole light control with gesture and app",
      
      backgroundMotivation: {
        whyTreeShadows: "We chose the motif of tree shadows, a universally recognized and tranquil visual, to foster serenity and uplift moods.",
        objectiveVision: "Our goal was to incorporate natural calmness into indoor spaces by mimicking dappled tree shadows."
      },
      rolesResponsibilities: [
        "Concept Analysis: Analyzing the theme of light and shadow.",
        "Preliminary Research:   Selection of materials and evaluation of hardware/software.",
        "Prototype Refinement:   Improving the prototype's design for an authentic shadow effect.",
       "UI and Functional Design:   Creating the user interface and defining functions.",
       "Circuitry Layout:   Planning and implementing the circuitry.",
"Control Code Writing:   Crafting the control code for lights, colors, and switches.",
"Testing:   Rigorous hardware and software testing.",
"Rapid Prototyping:   Using Protopie for prototype development.",
"Final Presentation:   Successful display of the final product.",
      ],
      challenges: [
        "Main Challenge: Achieving the perfect interplay of light and shadow to induce calming effects.",
"Multifaceted Responsibility: Integrated circuit design, hardware connections, and light/shadow design.",
"My Approach: Leveraging my background in IT, I swiftly navigated complex integrations, not just crafting control code but holistically integrating various aspects.",]
        ,
      showcase: {

        demonstration: "A hands-on experience of controlling lights, colors, and switches through gesture sensing and mobile applications.",
        userFeedback: "If available, include user reviews or testimonials here"
      },
      conclusion: "A successfully implemented lamp that recreates the soothing ambiance of tree shadows.",
      acknowledgments: "Special thanks to My team members Kexin Zhou and Yunxi Zhang",
    },
  ];
  
  export default posts;